import { SceneComponent } from "@mp/common";

class MusicPlayer extends SceneComponent {
    inputs = {
        src: '',
        autoplay: false,
        loop: false,
        volume: 1,
    };

    private audio: HTMLAudioElement | null = null;

    onInit() {
        this.audio = new Audio();
        this.audio.src = this.inputs.src;
        this.audio.autoplay = this.inputs.autoplay;
        this.audio.loop = this.inputs.loop;
        this.audio.volume = this.inputs.volume;
        this.audio.muted = true;
        this.audio.load();
        this.audio.addEventListener('canplaythrough', () => {
            // this.audio.play();
        });
    }

    onInputsUpdated(oldInputs: any) {
        if (oldInputs.src !== this.inputs.src) {
            this.audio.src = this.inputs.src;
            this.audio.load();
        }

        if (oldInputs.autoplay !== this.inputs.autoplay) {
            this.audio.autoplay = this.inputs.autoplay;
        }

        if (oldInputs.loop !== this.inputs.loop) {
            this.audio.loop = this.inputs.loop;
        }

        if (oldInputs.volume !== this.inputs.volume) {
            this.audio.volume = this.inputs.volume;
        }
    }

    onDestroy() {
        if (this.audio) {
            this.audio.pause();
            this.audio = null;
        }
    }
}

export const musicPlayerType = 'mp.musicPlayer';
export function makeMusicPlayer() {
    return new MusicPlayer();
}