// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.frame {
  height: 100%;
  width: 100%;
  border-width: 0px;
}

.main {
  height: 100%;
  width: 100%;
}

.list-wrapper {
  z-index: 100;
  position: fixed;
  right: 10px;
  top: 10px;
  float: right;
  width: 120px;
  background-color: whitesmoke;
}
`, "",{"version":3,"sources":["webpack://./src/main.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,WAAW;EACX,SAAS;EACT,YAAY;EACZ,YAAY;EACZ,4BAA4B;AAC9B","sourcesContent":[".frame {\n  height: 100%;\n  width: 100%;\n  border-width: 0px;\n}\n\n.main {\n  height: 100%;\n  width: 100%;\n}\n\n.list-wrapper {\n  z-index: 100;\n  position: fixed;\n  right: 10px;\n  top: 10px;\n  float: right;\n  width: 120px;\n  background-color: whitesmoke;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
