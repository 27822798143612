import { SceneComponent } from '@mp/common/src/SceneComponent';
import { AnimationMixer, AnimationAction, AnimationClip } from 'three';

interface Inputs {
    object: any;
    clip: any;
    animationSpeed: number;
}

export class Animator extends SceneComponent {

    private mixer: AnimationMixer | null = null;
    private clipAction: AnimationAction | null = null;
    private lastAction: AnimationAction | null = null;

    inputs: Inputs = {
        object: null,
        clip: null,
        animationSpeed: 1
    };

    onInit() {
        if(!this.inputs.object) return;
        this.setModelAnimation(this.inputs.object, '');
    }

    onInputsUpdated(oldInputs : any){
        if(!this.inputs.object) return;
        if(oldInputs.clip != this.inputs.clip || !this.mixer) {
            this.setModelAnimation(this.inputs.object, this.inputs.clip);
        }
    }

    onTick(tickDelta : number){
        if (this.mixer)
            this.mixer.update(this.inputs.animationSpeed * tickDelta/1000);
    }

    onDestroy(){}

    setModelAnimation(object: any, clipName: string){
        const THREE = this.context.three;
        this.mixer = new THREE.AnimationMixer(object);
        
        // this.clipAction = this.mixer.clipAction(THREE.AnimationClip.findByName(this.findAnimations(object), clipName));
        
        let toAction = this.mixer.clipAction(this.inputs.clip);
        // check if this.clipAction is null
        if (this.clipAction) {
            if (toAction != this.clipAction) {
                this.lastAction = this.clipAction;
                this.clipAction = toAction;
                this.lastAction.fadeOut(1);
                this.clipAction.reset();
                this.clipAction.fadeIn(1);
                this.clipAction.play();
            }
        } else {
            this.clipAction = this.mixer.clipAction(this.inputs.clip);
            this.clipAction.play();
        }
    }

    findAnimations(object: any){
        const animations : AnimationClip[] = [];
        object.traverse((obj : any) => {
            if (obj.animations) {
                obj.animations.forEach((anim : any) => animations.push(anim));
            }
        });
        console.log(animations)
        return animations;
    }
}

export const animatorType = 'mp.animator';
export const makeAnimator = function() {
  return new Animator();
}